import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout/js/layout';
import SEO from '../components/seo/js/seo';
import { siteMetadata } from '../../gatsby-config';

const NotFoundPage = ({ location }) => (
	<Layout location={location}>
		<SEO site={siteMetadata} title="Fehler" />
		<div className="g-container">
			<div className="g-row">
				<div className="g-col-12">
					<article className="h-mt-4 h-mb-4">
						<h2>Nein!</h2>
						<p>Ein Fehler 404 ist aufgetreten</p>
						<Link to="/">Zur Startseite</Link>
					</article>
				</div>
			</div>
		</div>
	</Layout>
);

export default NotFoundPage;
